import React from 'react';
import { LayoutGuide, LayoutStore } from '../../compnents/layout';
import styles from './index.less';

// 导航 示例
export default class MenuPage extends React.Component {
  constructor(prop) {
    super(prop);
    this.layoutStore = new LayoutStore({
      project: {
        name: '',
        value: 'egenie-erp-home',
      },
    });
    this.layoutStore.handleInit();
    document.title = '搭子';
  }

  public layoutStore: LayoutStore;

  componentDidMount(): void {}

  public getLogoImg = (): JSX.Element => (
    <div className={styles.pageTitle}>搭子</div>
    // <img
    //   alt="加载失败"
    //   onDragStart={(e) => {
    //     e.preventDefault();
    //   }}
    //   src={`${''}/images/erpLogo.png`}
    //   style={{ width: '100%' }}
    // />
  );

  render(): JSX.Element {
    return (
      <div style={{ position: 'relative' }}>
        <LayoutGuide
          defaultDashboard={
            <div className={styles.homePage}>
              <span>搭子后台管理系统</span>
            </div>
          }
          haveDashboard={false}
          logoImg={this.getLogoImg()}
          store={this.layoutStore}
        />
      </div>
    );
  }
}
